import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Subscribe from './components/Subscribe';
import SessionSelection from './components/SessionSelection';
import UnguidedSession from './components/UnguidedSession';
import ARTSession from './components/ARTSession';
import Sidebar from './components/Sidebar';
import DotContext from './contexts/DotContext';
import SessionHistory from './components/SessionHistory';
import SignInSignUp from './components/SignInSignUp';
import SessionEnd from './components/SessionEnd';
import './App.css';

function App() {
  const [dotColor, setDotColor] = useState('black'); // Default color of the dot
  const [bgColor, setBgColor] = useState('#FFF8F0'); // Default background color
  const [dotSize, setDotSize] = useState(20); // Default size of the dot
  const [dotSpeed, setDotSpeed] = useState(90); // Default animation speed of the dot
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [shouldRun, setShouldRun] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [exitSession, setExitSession] = useState(false);
  // const isSubscribed = true; // Placeholder for determining the subscription status
  

  return (
    <DotContext.Provider value={{
      dotColor,
      setDotColor,
      bgColor,
      setBgColor,
      dotSize,
      setDotSize,
      dotSpeed,
      setDotSpeed,
      sidebarVisible, 
      setSidebarVisible,
      shouldRun,
      setShouldRun,
      isPaused,
      setIsPaused,
      exitSession,
      setExitSession,
    }}>
    <Router>
      <Sidebar
        isVisible={sidebarVisible}
        closeSidebar={() => setSidebarVisible(false)}
        dotColor={dotColor}
        setDotColor={setDotColor}
        bgColor={bgColor}
        setBgColor={setBgColor}
        dotSize={dotSize}
        setDotSize={setDotSize}
        dotSpeed={dotSpeed}
        setDotSpeed={setDotSpeed}
        exitSession={exitSession}
        setExitSession={setExitSession}
      />

      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/login" element={<SignInSignUp />} />
        {/* SubscriberArea route managed separately to only show when logged in */}
        <Route path="/sessions/*" element={<SessionSelection />} />
        <Route path="/sessions/art" element={<ARTSession />} /> 
        <Route path="/sessions/unguided" element={<UnguidedSession />} />
        <Route path="/session-history" element={<SessionHistory />} />
        <Route path="/session-end" element={<SessionEnd />} />
        {/* Home route */}
        <Route path="/" element={<Home />} />
      </Routes>
      
    </Router>
    </DotContext.Provider>
  );
}

export default App;
